import React, { useEffect, useState } from 'react';
import { Box, Portal, Button, Typography } from '@mui/material';
import PinField from 'react-pin-field';
import colors from 'theme/colors';
import { hideOverflow, showOverflow } from 'helpers/Common';
import useAxiosRequest from 'hooks/useApiRequest';
import { checkParentControlPassword, recoverPin } from 'api/authApi';
import DialogWrapper from 'components/Dialog/Wrapper';
import { useSnackbar } from 'notistack';
import { setPin } from 'api/userApi';
import { useTranslation } from 'react-i18next';

interface Props {
  isShow?: boolean;
  setIsShow: () => void;
}

const ParentControl: React.FC<Props> = ({ isShow, setIsShow }: Props): JSX.Element => {
  const { requestFunc, setIsError, isError } = useAxiosRequest();
  const [pin, setPin] = useState('');
  const [pinError, setPinError] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCheckProtectionPassword = async () => {
    if (pin.length === 4) {
      setPinError(false);
      const res = await requestFunc(checkParentControlPassword(pin));
      if (res) {
        enqueueSnackbar(res.message, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        setIsShow();
      } else {
        setPinError(true);
      }
    }
  };

  const handleRecoveryPin = async () => {
    const res = await requestFunc(recoverPin());   
    console.log(res);
    if (res) {
      enqueueSnackbar(res.message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      setIsShow();
    } 
  };

  useEffect(() => {
    if (isShow) {
      hideOverflow();
    }
    return () => {
      showOverflow();
    };
  }, [isShow]);

  useEffect(() => {
    if (isError) {
      setIsError(false);
    }
  }, [setIsError, isError]);

  return (
    <>
      {isShow && (
         <DialogWrapper isOpen={isShow} title={t('settings.parentControl.title')}>
         <Box
           sx={{
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
           }}
         >
           <Typography
             textAlign='center'
             fontSize={'20px'}
             sx={{
               width: '100%',
               maxWidth: '720px',
               mb: 3,
             }}
           >
             {t('settings.parentControl.description1')}
             <br/>
             {t('settings.parentControl.description2')}
           </Typography>
           <Box
             sx={{
               position: 'relative',
               zIndex: 4,
               '& input[type="password"]': {
                 width: 40,
                 height: 40,
                 fontSize: 16,
                 color: colors.white,
                 textAlign: 'center',
                 backgroundColor: 'transparent',
                 borderWidth: 2,
                 borderStyle: 'solid',
                 borderColor: colors.white,
                 borderRadius: 2,
                 outline: 'none',
                 '&:not(last-child)': {
                   mr: 2,
                 },
                 '&:focus': {
                   cursor: 'pointer',
                   borderColor: colors.white,
                 },
               },
             }}
           >
             <PinField
               type='password'
               length={4}
               autoComplete='new-password'
               autoFocus
               onChange={(value) => setPin(value)}    
               onComplete={() => setPinError(false)}   
               style={{              
                borderColor: pinError ? 'red' : colors.white,
              }}
            />
            {pinError && (
              <Typography color="error" fontSize="14px" mt={1}>
                {t('settings.parentControl.incorrectPin')}
              </Typography>
            )}
           </Box>
           <Box
             sx={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'center',
               mt: 3,
               '& .MuiButton-root': {
                 fontSize: 20,
                 '&:first-of-type': {
                   mr: 4,
                 },
               },
             }}
           >
             <Button
               variant='outlined'
               size='large'
               onClick={handleCheckProtectionPassword}>                
               {t('settings.parentControl.unlock')}
             </Button>
             <Button variant='outlined' size='large'
              sx={{
                borderColor: colors.white,
              }} 
               onClick={handleRecoveryPin}>
              {t('settings.parentControl.recover')}
             </Button>
           </Box>
         </Box>
       </DialogWrapper>      
      )}
    </>
  );
};

export default ParentControl;
