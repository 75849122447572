import { apiClient } from './apiClient';
import {
  LoginParams,
  LoginPromise,
  UserData,
  RememberPasswordPromise,
  RememberPasswordResponse,
  ParentProtectionPromise,
  ParentControlResponse,
  RegisterPromise,
  RegisterResponse,
  RegisterParams,
  OTPPromise,
  OTPRequestResponse,
  OTPConfirmationBody,
  OTPConfirmationResponse,
  OTPConfirmationPromise,
  LoginWithSessionIDParams,
  RegisterParamsWithSessionID,
  RegisterWithVoucher,
  LoginRequestParams,
} from 'types/Auth';
import ENDPOINTS from './endpoints';
import { CurrentProfile, CurrentProfilePromise, UpdatePasswordSchema } from 'types/Profile';

const endpoint = ENDPOINTS.AUTH_USER;
const endpointV2 = ENDPOINTS.AUTH_USER_V2;

const authApiUrl = {
  authenticate: `${endpoint}/authenticate`,
  register: `${endpoint}/register`,
  setProfile: `${endpoint}/setprofile`,
  forgotPassword: `${endpoint}/forgotpassword`,
  checkPin: `${endpoint}/checkpin`,
  recoverPin: `${endpoint}/pinrecover`,
  otpRequest: `${endpoint}/otprequest`,
  otpConfirmation: `${endpoint}/otpconfirmation`,
  changePassword: `${endpoint}/changepassword`,
  deactivate: `${endpointV2}/deactivate`,
};

const signIn = ({ Email, Password }: LoginParams, params?: LoginRequestParams): LoginPromise =>
  apiClient
    .post<UserData>(
      authApiUrl.authenticate,
      {
        Email,
        Password,
      },
      {
        params,
      },
    )
    .then((res) => res.data);

const signInWithSessionID = ({
  Email,
  Password,
  QRSessionID,
}: LoginWithSessionIDParams): LoginPromise =>
  apiClient
    .post<UserData>(authApiUrl.authenticate, {
      Email,
      Password,
      QRSessionID,
    })
    .then((res) => res.data);

const registerAccount = (data: RegisterParams): RegisterPromise =>
  apiClient.post<RegisterResponse>(authApiUrl.register, data).then((res) => res.data);

const registerAccountWithVoucher = (data: RegisterWithVoucher): RegisterPromise =>
  apiClient.post<RegisterResponse>(authApiUrl.register, data).then((res) => res.data);

const registerAccountWithSessionID = (data: RegisterParamsWithSessionID): RegisterPromise =>
  apiClient.post<RegisterResponse>(authApiUrl.register, data).then((res) => res.data);

const setCurrentProfile = (id: number | string): CurrentProfilePromise =>
  apiClient.post<CurrentProfile>(authApiUrl.setProfile, { ProfileId: id }).then((res) => res.data);

const rememberPassword = (Email: string): RememberPasswordPromise =>
  apiClient
    .post<RememberPasswordResponse>(authApiUrl.forgotPassword, { Email })
    .then((res) => res.data);

const changePassword = (body: UpdatePasswordSchema): Promise<{ message: string }> =>
  apiClient
    .post<{ message: string }>(authApiUrl.changePassword, { ...body })
    .then((res) => res.data);

const checkParentControlPassword = (pin: number | string): ParentProtectionPromise =>
  apiClient.post<ParentControlResponse>(authApiUrl.checkPin, { pin }).then((res) => res.data);

const recoverPin = (): ParentProtectionPromise =>
  apiClient.post<ParentControlResponse>(authApiUrl.recoverPin).then((res) => res.data);

const otpRequest = (PhoneNumber: string): OTPPromise =>
  apiClient
    .post<OTPRequestResponse>(authApiUrl.otpRequest, { PhoneNumber })
    .then((res) => res.data);

const otpConfirmation = (value: OTPConfirmationBody): OTPConfirmationPromise =>
  apiClient
    .post<OTPConfirmationResponse>(authApiUrl.otpConfirmation, value)
    .then((res) => res.data);

const deactivateAccount = (data: {
  email: string;
  password: string;
}): Promise<{ message: string }> =>
  apiClient.post(authApiUrl.deactivate, data).then((res) => res.data);
export {
  signIn,
  signInWithSessionID,
  registerAccount,
  registerAccountWithVoucher,
  registerAccountWithSessionID,
  setCurrentProfile,
  rememberPassword,
  checkParentControlPassword,
  recoverPin,
  otpRequest,
  otpConfirmation,
  changePassword,
  deactivateAccount,
};
