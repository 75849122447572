import ReactGA from 'react-ga4';
import { Location } from 'react-router-dom';
import { useAppContext } from 'store';

export enum EventNames {
  ADD_CANCEL_STREAMERS = 'Add/Cancel streamers or services',
  CLICK_MENU_ITEM = 'Click on menu item',
  CLICK_SETTINGS = 'Click on settings',
  USE_DISCOVER = 'Use of Discover',
  USE_TV_GUIDE = 'User of TV Guide',
  STREAMERS_WATCHED = 'Streamers watched',
  CONTENT_ADDED_TO_WATCHLIST = 'Contents added in Watch List',
  CLICK_CONTENT_TV_GUIDE = 'Click on contents from TV Guide',
  CLICK_CONTENT_DISCOVER = 'Click on contents from Discover',
  PLAY_MAIN_TRAILER = 'Play on Main Trailer',
  FIRST_CLICK_HOME = 'First click on Home',
  PROFILE_WATCHING = 'Which profile is watching section',
  CLICK_TRENDING_NOW = 'Click on Trending Now section',
  CLICK_HIGHLIGHTS = 'Click on Highlights section',
  CLICK_CONTINUE_WATCHING = 'Click on Continue watching section',
  CLICK_JUST_FOR_YOU = 'Click on Just for you section',
  CLICK_WATCH_LIST = 'Click on Watch list section',
  STV_CLICK_ON_SIGN_UP_LANDING = 'stv_click_on_sing_up_[landing_page]',
  STV_OPEN_REGISTRATION_STEP_1 = 'stv_open_registration_[step_1]',
  STV_CLICK_ON_REGISTER_STEP_1 = 'stv_click_on_register_[step_1]',
  STV_ERROR_STEP_1 = 'stv_error_[step_1]',
  STV_OPEN_STREAMER_SELECTION_STEP_2 = 'stv_open_streamer_selection_[step_2]',
  STV_SELECT_STREAMER_STEP_2 = 'stv_select_streamer_[step_2]',
  STV_CLICK_ON_CONTINUE_STEP_2 = 'stv_click_on_continue_[step_2]',
  STV_OPEN_PREPAYMENT_RECEIPT_STEP_3 = 'stv_open_prepayment_receipt_[step_3]',
  STV_CLICK_ON_CONTINUE_STEP_3 = 'stv_click_on_continue_[step_3]',
  STV_OPEN_PAYMENT_DETAILS_STEP_4 = 'stv_open_pd_[step_4]',
  STV_PAYMENT_DETAILS_REGISTERED_SUCCESSFULLY_STEP_4 = 'stv_pd_registered_successfully_[step_4]',
  STV_PAYMENT_DETAILS_ERROR_STEP_4 = 'stv_pd_error_[step_4]',
  STV_REGISTRATION_COMPLETE_STEP_4 = 'stv_registration_complete_[step_4]',
  STV_CLICK_ON_HIGHLIGHTS_LANDING_PAGE = 'stv_click_on_highlights_[landing_page]',
  STV_CLICK_ON_ADD_STREAMER_LANDING_PAGE = 'stv_click_on_add_streamer_[landing_page]',
  STV_SELECT_SEARCH_RESULT_LANDING_PAGE = 'stv_select_search_result_[landing_page]',
  STV_CLICK_ON_WHERE_TO_WATCH_LANDING_PAGE = 'stv_click_on_where_to_watch_[landing_page]',
  STV_CLICK_ON_SUBSCRIBE_NOW_TO_WATCH_LANDING_PAGE = 'stv_click_on_subscribe_now_to_watch_[landing_page]'
}

// Define types for event parameters
export interface EventParams {
  application_type?: string;
  profile_id?: number;
  profile_name?: string;
  selected_services?: string;
  media_name?: string;
  channel?: string;
  is_series?: boolean;
  section_name?: string;
  page_name?: string;
  key?: string;
  message?: string;
  page?: string;
  is_streaming?: boolean;
}

// Hook for tracking events in Google Analytics
const useGoogleAnalytics = () => {
  const {
    state: { currentProfile },
  } = useAppContext();

  const googleAnalyticsInit = (trackingId: string) => {
    if (!trackingId) {
      console.warn('Google Analytics Tracking ID is not provided');
      return;
    }

    ReactGA.initialize(trackingId);
  };

  const trackPageView = (location: Location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  };

  const trackCustomEvent = (eventName: EventNames, eventParams: EventParams) => {
    ReactGA.event(eventName, {
      ...eventParams,
      profile_id: currentProfile.activeProfile,
      profile_name: currentProfile.profileName,
    } as EventParams);
  };

  const createEventTracker = (
    eventName: EventNames,
    params: Partial<Omit<EventParams, 'application_type'>> = {},
  ) => {
    return (additionalParams: Partial<EventParams> = {}) => {
      trackCustomEvent(eventName, { application_type: 'WebApp', ...params, ...additionalParams });
    };
  };

  // Custom event functions using the factory function with enum
  const trackAddCancelStreamers = createEventTracker(EventNames.ADD_CANCEL_STREAMERS);
  const trackClickOnMenuItem = createEventTracker(EventNames.CLICK_MENU_ITEM);
  const trackClickOnSettings = createEventTracker(EventNames.CLICK_SETTINGS);
  const trackUseOfDiscover = createEventTracker(EventNames.USE_DISCOVER);
  const trackUseOfTVGuide = createEventTracker(EventNames.USE_TV_GUIDE);
  const trackStreamersWatched = createEventTracker(EventNames.STREAMERS_WATCHED);
  const trackContentAddedToWatchList = createEventTracker(EventNames.CONTENT_ADDED_TO_WATCHLIST);
  const trackClickOnContentFromTVGuide = createEventTracker(EventNames.CLICK_CONTENT_TV_GUIDE);
  const trackClickOnContentFromDiscover = createEventTracker(EventNames.CLICK_CONTENT_DISCOVER);
  const trackPlayOnMainTrailer = createEventTracker(EventNames.PLAY_MAIN_TRAILER);
  const trackFirstClickOnHome = createEventTracker(EventNames.FIRST_CLICK_HOME);
  const trackProfileWatching = createEventTracker(EventNames.PROFILE_WATCHING);
  const trackClickOnTrendingNow = createEventTracker(EventNames.CLICK_TRENDING_NOW);
  const trackClickOnHighlights = createEventTracker(EventNames.CLICK_HIGHLIGHTS);
  const trackClickOnContinueWatching = createEventTracker(EventNames.CLICK_CONTINUE_WATCHING);
  const trackClickOnJustForYou = createEventTracker(EventNames.CLICK_JUST_FOR_YOU);
  const trackClickOnWatchList = createEventTracker(EventNames.CLICK_WATCH_LIST);
  const trackClickOnSignUpLanding = createEventTracker(EventNames.STV_CLICK_ON_SIGN_UP_LANDING);
  const trackOpenRegistrationStep1 = createEventTracker(EventNames.STV_OPEN_REGISTRATION_STEP_1);
  const trackClickOnRegisterStep1 = createEventTracker(EventNames.STV_CLICK_ON_REGISTER_STEP_1);
  const trackErrorStep1 = createEventTracker(EventNames.STV_ERROR_STEP_1);
  const trackOpenStreamerSelectionStep2 = createEventTracker(EventNames.STV_OPEN_STREAMER_SELECTION_STEP_2);
  const trackSelectStreamerStep2 = createEventTracker(EventNames.STV_SELECT_STREAMER_STEP_2);
  const trackClickOnContinueStep2 = createEventTracker(EventNames.STV_CLICK_ON_CONTINUE_STEP_2);
  const trackOpenPrepaymentReceiptStep3 = createEventTracker(EventNames.STV_OPEN_PREPAYMENT_RECEIPT_STEP_3);
  const trackClickOnContinueStep3 = createEventTracker(EventNames.STV_CLICK_ON_CONTINUE_STEP_3);
  const trackOpenPaymentDetailsStep4 = createEventTracker(EventNames.STV_OPEN_PAYMENT_DETAILS_STEP_4);
  const trackPaymentDetailsRegisteredSuccessfullyStep4 = createEventTracker(EventNames.STV_PAYMENT_DETAILS_REGISTERED_SUCCESSFULLY_STEP_4);
  const trackPaymentDetailsErrorStep4 = createEventTracker(EventNames.STV_PAYMENT_DETAILS_ERROR_STEP_4);
  const trackRegistrationCompleteStep4 = createEventTracker(EventNames.STV_REGISTRATION_COMPLETE_STEP_4);  
  const trackClickOnHighlightsLandingPage = createEventTracker(EventNames.STV_CLICK_ON_HIGHLIGHTS_LANDING_PAGE);
  const trackClickOnAddStreamerLandingPage = createEventTracker(EventNames.STV_CLICK_ON_ADD_STREAMER_LANDING_PAGE);
  const trackSelectSearchResultLandingPage = createEventTracker(EventNames.STV_SELECT_SEARCH_RESULT_LANDING_PAGE);
  const trackClickOnWhereToWatchLandingPage = createEventTracker(EventNames.STV_CLICK_ON_WHERE_TO_WATCH_LANDING_PAGE);
  const trackClickOnSubscribeNowToWatchLandingPage = createEventTracker(EventNames.STV_CLICK_ON_SUBSCRIBE_NOW_TO_WATCH_LANDING_PAGE);

  return {
    googleAnalyticsInit,
    trackPageView,
    trackAddCancelStreamers,
    trackClickOnMenuItem,
    trackClickOnSettings,
    trackUseOfDiscover,
    trackUseOfTVGuide,
    trackStreamersWatched,
    trackContentAddedToWatchList,
    trackClickOnContentFromTVGuide,
    trackClickOnContentFromDiscover,
    trackPlayOnMainTrailer,
    trackFirstClickOnHome,
    trackProfileWatching,
    trackClickOnTrendingNow,
    trackClickOnHighlights,
    trackClickOnContinueWatching,
    trackClickOnJustForYou,
    trackClickOnWatchList,    
    trackClickOnSignUpLanding,
    trackOpenRegistrationStep1,
    trackClickOnRegisterStep1,
    trackErrorStep1,
    trackOpenStreamerSelectionStep2,
    trackSelectStreamerStep2,
    trackClickOnContinueStep2,
    trackOpenPrepaymentReceiptStep3,
    trackClickOnContinueStep3,
    trackOpenPaymentDetailsStep4,
    trackPaymentDetailsRegisteredSuccessfullyStep4,
    trackPaymentDetailsErrorStep4,
    trackRegistrationCompleteStep4,
    trackClickOnHighlightsLandingPage,
    trackClickOnAddStreamerLandingPage,
    trackSelectSearchResultLandingPage,
    trackClickOnWhereToWatchLandingPage,
    trackClickOnSubscribeNowToWatchLandingPage
  };
};

export default useGoogleAnalytics;
