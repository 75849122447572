import axios, { AxiosInstance } from 'axios';
import { VOUCHER_TYPES } from 'theme/const';

function clearAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
}

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // If the response is successful (status code 2xx), simply return the response.
    return response;
  },
  async (error) => {
    // Check if the error is a 401
    if (error.response && (error.response.status === 401 || error.response.status === 404)) {
      console.log('401 Unauthorized error occurred. Redirecting to login...');

      if (error.response?.config?.headers?.Authorization) {
        window.localStorage.clear();
        clearAllCookies();
        window.location.href = '/';
        return;
      }

      return Promise.reject(error);
    }

    // If it's a different error (e.g., 403, 500), return the error as usual
    return Promise.reject(error);
  },
);

const setAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const deleteAuthorizationHeader = () => {
  delete apiClient.defaults.headers.common['Authorization'];
};

const setDeviceType = (type: string): void => {
  apiClient.defaults.headers.common['Device-Type'] = type;
};

const setApiLanguageHeader = (language: string): void => {
  apiClient.defaults.headers.common['language'] = language;
};

const setQrpSessionHeader = (key: string, session_id: string) => {
  apiClient.defaults.headers.common[key] = session_id;
};

const setQrfSessionHeader = (type: VOUCHER_TYPES,) => {
  apiClient.defaults.headers.common['qrf'] = type;
};

const getAuthorizationHeader = () => apiClient.defaults.headers.common['Authorization'];

export {
  apiClient,
  setAuthorizationHeader,
  setDeviceType,
  setApiLanguageHeader,
  setQrpSessionHeader,
  setQrfSessionHeader,
  getAuthorizationHeader,
  deleteAuthorizationHeader,
};
