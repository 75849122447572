const initUserData = {
  username: '',
  name: '',
  role: '',
  startDate: '',
  language: '',
  message: '',
  token: '',
  isSubscriptionActive: false,
};

const initSelectedMedia = {
  id: 0,
  mediaId: 0,
  title: '',
  thumbnailLandscape: '',
  thumbnailSquare: '',
  logline: '',
  synopsis: '',
  rating: '',
  cast: '',
  directors: '',
  viewProgress: 0,
  firstAired: '',
  runtime: 0,
  info: '',
  isSeries: false,
  trailer: '',
  isFavourite: false,
  landscapeKeyArt: '',
  seasons: [],
  sources: [],
  logo: '',
  titleTreatment: '',
  displayGenres: '',
};

const initSelectedProfile = {
  id: 0,
  name: '',
  userId: '',
  isKid: false,
  isProtected: false,
  thumbnail: '',
  type: 0,
};

const initBackground = {
  url: '',
  type: '',
  page: '',
};

const iniEpgProgram = {
  logo: '',
  stream: '',
  isStreaming: false,
  channelId: 0,
  epg: {
    title: '',
    description: '',
    displayYear: null,
    displayInfo: null,
    date: '',
    start: '',
    end: '',
    duration: '',
    isStreaming: false,
  },
};

const initCurrentProfile = {
  username: '',
  activeProfile: 0,
  profileName: '',
  message: '',
  token: '',
};

const initSelectedHighlight = {
  id: 0,
  mediaId: 0,
  title: '',
  thumbnailLandscape: '',
  thumbnailSquare: '',
  landscapeKeyArt: '',
  logline: '',
  synopsis: '',
  displayYear: '',
  displayGenres: '',
  displayInfo: null,
  displayChannel: '',
  isSeries: false,
  channelId: 1,
  cast: '',
  producers: '',
  directors: '',
  logoColoured: '',
  logo: null,
  genres: null,
  titleTreatment: '',
  trailer: '',
  trailerLow: '',
  affiliateLink: null,
  affiliateBanner: null,
};

export {
  initUserData,
  initSelectedMedia,
  initSelectedProfile,
  initBackground,
  iniEpgProgram,
  initCurrentProfile,
  initSelectedHighlight,
};
