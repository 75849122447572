import { useCallback, useMemo } from 'react';
import { alpha, Box, Button, Stack, Typography } from '@mui/material';
import { AssetChannels } from 'types/LandingPage';
import { Swiper, SwiperSlide } from 'swiper/react';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow } from 'swiper';
import colors from 'theme/colors';
import { useTranslation } from 'react-i18next';

interface Props {
  channels: AssetChannels | undefined;
}

const WatchITCarousel: React.FC<Props> = ({ channels }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { trackClickOnSubscribeNowToWatchLandingPage } = useGoogleAnalytics();
  
  const initialSlide = useMemo(() => {
    return channels && channels?.length >= 3 ? 1 : 0;
  }, [channels]);

  const cardTitleText = useCallback(
    (isStreamed: boolean): string => {
      return isStreamed
        ? t('landing.universalSearch.watchItCarousel.streamIt')
        : t('landing.universalSearch.watchItCarousel.rentIn');
    },
    [t],
  );

  const handleButtonClick = (link: string) => {
    window.open(link, '_blank');
    trackClickOnSubscribeNowToWatchLandingPage();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 1,
        px: { xs: 1, lg: 4 },
        '& .swiper': {
          width: 1,
          '& .swiper-slide': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: { xs: '60%', lg: '52%' },
            height: { xs: 'fit-content', lg: 350 },
            py: 2,
            borderRadius: { xs: '15px', lg: '30px' },
            backgroundColor: alpha(colors.white, 0.2),
            backdropFilter: 'blur(50px)',
          },
        },
      }}
    >
      <Swiper
        initialSlide={initialSlide}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 20,
          depth: 300,
          modifier: 2,
          slideShadows: false,
        }}
        modules={[EffectCoverflow]}
      >
        {channels?.map((channel) => (
          <SwiperSlide key={channel.id}>
            <Stack
              direction='column'
              spacing={3}
              alignItems='center'
              sx={{
                p: 2,
              }}
            >
              <Typography color={colors.primary} textTransform='uppercase' textAlign='center'>
                {cardTitleText(channel.isStreamed)}
              </Typography>
              <Box
                sx={{
                  width: 1,
                  height: 80,
                  background: `url(${channel.logoColoured})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }}
              />
              <Button
                variant='outlined'
                onClick={() => handleButtonClick(channel.link)}
                sx={{
                  cursor: 'pointer',
                  fontSize: { xs: '0.6em', lg: '1.2em' },
                }}
              >
                {t('landing.universalSearch.mediaPopup.subscribeButton')}
              </Button>
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default WatchITCarousel;
